import React from "react"
import asset404 from "assets/img/icon/misc/404.svg"
// import ufo from "assets/img/icon/misc/Error 404.svg"
import withLottie from 'assets/lottie/404.json';
import { Button, Image } from "components/anti"
import SEO from "components/seo"
import { Helmet } from "react-helmet"
import Layout from "components/layout"
import Lottie from "react-lottie";

export default function Page404() {
  return (
    <>
      <SEO title="Page Not Found" />
      <Helmet>
        <body className="bd-404" />
      </Helmet>
      <Layout noFooter>
        <section className="sc-404">
          <div className="container mw-md">
            <div className="row row-5">
              <div className="col-md-6 col-img">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: withLottie,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice"
                    }
                  }}
                  width={328}
                  isClickToPauseDisabled={true}
                  style={{ margin: 0 }}
                />
              </div>
              <div className="col-md-6 col-text">
                <Image src={asset404} className="img-fluid" />
                <h2 className="title">Page Not Found</h2>
                <p className="subtitle">
                  We couldn’t find what you were looking for.
                </p>
                <Button variant="outline-light" className="text-12 px-4 py-2" link="/">Back to Home</Button>                
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
